@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Markazi+Text:wght@400..700&display=swap');

.main{
    width: 100%;
    height: 100%;
    background: #ffff;
    display: block;
    overflow: hidden;
}
.header{
    width: 100%;
    height: 100vh;
    background: url('../img/familia_.jpg') no-repeat center center/cover;
    position: absolute;
    z-index: 500;
    align-items: center;
    text-align: center;
    overflow: hidden;

}
.header-layer::before{
    content: "";
    width: 100%;
    height: 100%;
    background: var(--main);
    opacity: 65%;
    position: absolute;
    top: 0;
    left: 0;
}
.banner{
    width: 100%;
    height: 100px;
    /* background: var(--main); */
    position: absolute;
    display: flex;
    border-style: none none solid none;
    border-color: #ffff;
    border-width: 1px;
    
}
.banner ul{
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
}
.banner ul li{
    color: #ffff;
    list-style-type:none;
    padding: 40px 50px 0 0;

}
.corporate{
    width: 20px;
    height: 20px;
}
.box-msg{
    color: var( --text-secondary);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    /* background: #4c2424; */
    align-items: center;
    flex-direction: column;
}
.box-msg h1{
    text-align: center;
    margin: auto;
    font-family: "Markazi Text", serif;
    font-size: 4.1rem;
    padding: 0 20%;
    font-weight: 400;
}
.logo-2{
    display: none;
}

/* -----------MEDIA QUERIES--------------- */

@media only screen and (max-width: 900px) {
    .logo-bull {
    
      display: block;
    }
    .banner{
        display: none; 
    }
    .header{
        width: 100%;
        height: 100%;
    }
    .logo-2{
        display: block;
    }
    .box-msg h1{
        padding: 5%;
    }

}