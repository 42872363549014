/* Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Markazi+Text:wght@400..700&display=swap');
/* --- */

:root {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  --text-primary: #270505;
  --text-secondary: #ffff;

  /* Graphic colors */
  --main: #270505;
  --gray:#6f6e6d;
 
  /* Others */
  --transition-speed: 500ms;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}

